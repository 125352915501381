import React, { useEffect, useRef, useState } from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import About from "./component/About";
import Solutions from "./component/Solutions";
import Phase0page from "./component/Phase0page";
import Advantage from "./component/Advantage";
import ImageGallery from "./component/ImageGallery";
import Market from "./component/Market";
import Hero from "./component/Hero";
import Faq from "./component/Faq";

const Home = () => {
  const [isVisible, setIsVisible] = useState({});
  const sectionRefs = useRef({
    Market: null,
    About: null,
    Advantage: null,
    Solutions: null,
    Phase0page: null,
    ImageGallery: null,
    Faq: null,
  });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible((prevVisible) => ({
            ...prevVisible,
            [entry.target.dataset.section]: true,
          }));
        } else {
          setIsVisible((prevVisible) => ({
            ...prevVisible,
            [entry.target.dataset.section]: false,
          }));
        }
      });
    });

    const refs = sectionRefs.current;

    Object.values(refs).forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      Object.values(refs).forEach((ref) => {
        observer.unobserve(ref);
      });
    };
  }, []);

  const renderComponent = (sectionId, Component) => {
    return (
      <section
        id={sectionId}
        ref={(ref) => (sectionRefs.current[sectionId] = ref)}
        data-section={sectionId}
        className={isVisible[sectionId] ? "fade-in" : "fade-out"}
      >
        <Component />
      </section>
    );
  };
 
  // const main_bg = {
  //   backgroundImage: 'url("img/main_bg.jpg")',
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center 0px',
  // }
  

  return (
    <>
      <Header />
      <Hero />
      <section id="Hero-2">
        <h1>powered by tmat.io
        </h1>
      </section>
      <div className="main">
        {renderComponent("About", About)}
        {renderComponent("Advantage", Advantage)}
        {renderComponent("ImageGallery", ImageGallery)}
        {renderComponent("Market", Market)}
        {renderComponent("Phase0page", Phase0page)}
        {renderComponent("Solutions", Solutions)}
        {renderComponent("Faq", Faq)}
      </div>
      <Footer/>
    </>
  );
  
};

export default Home;
