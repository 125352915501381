import React from "react";
import MarketGraph from "./Graph/MarketGraph";

const Solutions = () => {
  return (
  <>
<div className="container">
<h1>Market Size</h1> 
<MarketGraph/>
{/* <p>In a dynamic business landscape, the market size is on the rise, presenting exciting opportunities for growth. We are focused on expanding our market reach and actively seeking new customers to tap into this expanding landscape. Join us on this journey as we explore fresh avenues and embrace the potential of a growing market.</p> */}
</div>

</>
  );
};

export default Solutions;