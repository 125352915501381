import React, { useEffect, useMemo, useRef } from 'react';
import ApexCharts from 'apexcharts';

const MarketGraph = () => {
  const chartRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);

  const chartOptions = useMemo(() => [
    {
      series: [80],
      labels: ['1.9 BILLION'],
      description: 'Total Available Market',
    },
    {
      series: [60],
      labels: ['53 MILLION'],
      description: 'Serviceable Available Market',
    },
    {
      series: [30],
      labels: ['10 MILLION'],
      description: 'Market Share',
    },
  ], []);

  useEffect(() => {
    const charts = chartOptions.map((option, index) => {
      const chart = new ApexCharts(chartRefs.current[index].current, {
        ...option,
        chart: {
          height: 350,
          type: 'radialBar',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 1000,
            animateGradually: {
              enabled: true,
              delay: 100000 // Delay before the first animation in milliseconds
            },
            dynamicAnimation: {
              enabled: true,
              speed: 300
            }
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              size: '80%',
            },
            dataLabels: {
              name: { show: false },
              value: { show: false },
              total: { show: false },
            },
            track: {
              background: '#FCE4DE', // Set the color for the rest of the chart
            },
          },
        },
        fill: {
          colors: ['#4B39B5'], // Change the fill color to red
        },
      });

      chart.render();
      return chart;
    });

    // Clean up the charts when the component unmounts
    return () => {
      charts.forEach((chart) => chart.destroy());
    };
  }, [chartOptions]);

  return (
    <div className="graph-container">
      {chartRefs.current.map((ref, index) => (
        <div className='graph' key={index}>
          <div id={`chart${index + 1}`} ref={ref}></div>
          <div className="labelName">{chartOptions[index].labels[0]}</div>
          <p>{chartOptions[index].description}</p>
        </div>
      ))}
    </div>
  );
};

export default MarketGraph;
