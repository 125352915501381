import React from "react";

const Phase0page = () => {

  const divStyle = {
    backgroundImage: 'url("img/rocket-img.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center 0px',
    
  };

  return (
<>
{/* <div className="container">
<div style={divStyle} className="rocket-container"><div className="rocket-title">Phase 0 - STUDIO</div></div>
<h1>Build (Go) or KILL (No-GO)</h1> 
<h2></h2>
<p><b>Step 1 - 30 Days:</b> Map out the end 2 end as-is business process and existing technologies in use today </p>
<p><b>Step 2 - 30 Days:</b> Identify hot spots for driving efficiency | Prioritise them into different types of use cases (Now, Next, New) </p>
<p><b>Step 3 - 30 Days:</b> Estimate cost to develop the use case | Approve a pilot budget | On-board team</p>
<p><b>Step 4 - 90 Days:</b> Develop and Deploy in a secure environment. </p>
<p><b>Step 5 - Build or Kill:</b> Examine the results and measure improvements | Go/No-go for a full scale build and roll out | Hand over to core technology team to scale </p>  
</div> */}
<div className="container">       <h1>Future Roadmap</h1>
            <h2>Build (Go) or KILL (No-GO)</h2>
            <div style={divStyle} className="rocket-container"><div className="rocket-title">Phase 0 - STUDIO</div></div>
        <div className="sub-container">
 
          <div className="cont-1">
  
              <p><b>Step 1 - 30 Days:</b><br/> Map out the end 2 end as-is business process and existing technologies in use today</p>
              <div className="vertical-line"></div> {/* Vertical line after the first <p> */}
              <p><b>Step 2 - 30 Days:</b><br/> Identify hot spots for driving efficiency | Prioritize them into different types of use cases (Now, Next, New)</p>
              <div className="vertical-line"></div> {/* Vertical line after the second <p> */}
              <p><b>Step 3 - 30 Days:</b><br/> Estimate cost to develop the use case | Approve a pilot budget | On-board team</p>
              <div className="vertical-line"></div> {/* Vertical line after the fourth <p> */}
              <p><b>Step 4 - 90 Days:</b><br/> Develop and Deploy in a secure environment.</p>
              <div className="vertical-line"></div> {/* Vertical line after the fourth <p> */}
              <p><b>Step 5 - Build or Kill:</b><br/> Examine the results and measure improvements | Go/No-go for a full-scale build and roll out | Hand over to the core technology team to scale</p>
       
          </div>

        </div>
      </div>


</>
  );
};

export default Phase0page;