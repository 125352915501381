import React, { useState } from "react";

const Faq = () => {
  // Create state to manage the open/closed state of each dropdown
  const [dropdowns, setDropdowns] = useState(Array(5).fill(false)); // 5 FAQs

  // Toggle the dropdown based on its index
  const toggleDropdown = (index) => {
    const newDropdowns = [...dropdowns];
    newDropdowns[index] = !newDropdowns[index];
    setDropdowns(newDropdowns);
  };

  const faqData = [
    {
      question: "What is tmat.io?",
      answer: "Tmat.io helps to run as an independent business unit ring fenced for your business and reduce risk and liability on your core services & operations.",
    },
    {
      question: "How does tmat.io work?",
      answer: "The Studio will support the existing business, innovation & tech teams to Identify use cases and quickly build & test MVPs.",
    },
    {
      question: "How is the budget counted?",
      answer: "We craft solutions tailored to your needs according to preferences and budget.",
    },
    {
      question: "How will my project be assisted?",
      answer: "On a quarterly basis, you can adjust the scope of the project, track performance through quarterly P&L, SLAs, and KPIs, all managed by our composite governance team. Plus, access senior consultants and executives at affordable rates.",
    },
    {
      question: "What is the ideal market for tmat.io?",
      answer: "Our ideal market are individual professionals, families and groups who looking for assistence for their first project.",
    },
  ];

  return (
    <div className="container">
    <h1>Frequently asked questions</h1>
      <div className="sub-container">
        {faqData.map((faq, index) => (
          <div className={`cont-1 ${dropdowns[index] ? "open" : ""}`} key={index}>
            <h2 className="faq-header" onClick={() => toggleDropdown(index)}>
              {faq.question}
              <span className={`arrow ${dropdowns[index] ? "open" : ""}`}>
              &gt;
              </span>
            </h2>
            <div className="answer-container">
              <p className={`answer ${dropdowns[index] ? "open" : ""}`}>
                {faq.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
     
    </div>
  );
};

export default Faq;
