import React from "react";
import ContactForm from "./ContactForm";

const Hero = () => {
  return (
    <section id="Hero">

<div className="container-rocket">
<div className="rocket">  <img src={"img/rocket.png"} alt="Logo" /></div>
    </div>
    <div className="container">
      <h2>PHASE 0 STUDIO</h2>
      <h3>For Pretotyping & INCUBATION </h3>
      <p>An AI driven app to assess competencies for tech roles <br/>
      We help to run Your independent business</p>
      <ContactForm />
    </div>
 
    </section>
  );
};

export default Hero;