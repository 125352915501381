import React, { useState } from "react";

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState("Let's talk");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (email === "") {
      // Email is required, prevent submission
      return;
    }
  
    setStatus("Sending...");
    const details = { email };
  
    // Send the POST request without saving the response
    await fetch("/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
  
    setStatus("Sent!");
  
    setSubmitted(true);
  };

  return (
    <div className="form-wrap">
      {submitted ? (
        <div className="submit-box">
          <h2>We will contact you shortly!</h2>
        </div>
      ) : (
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="input-bar fade-in">
              <label htmlFor="email"></label>
              <input
                type="email"
                id="email"
                placeholder="What is your email?"
                value={email}
                onChange={handleEmailChange}
              />
            </div>

            <button type="submit">{status}</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
