import React, { useEffect, useRef, useState } from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import About from "./component/About";
import Solutions from "./component/Solutions";
import Phase0page from "./component/Phase0page";
import Advantage from "./component/Advantage";
import ImageGallery from "./component/ImageGallery";
import Market from "./component/Market";
import Hero from "./component/Hero";
import Faq from "./component/Faq";

const Home = () => {
  const [isVisible, setIsVisible] = useState({});
  const sectionRefs = useRef({

  });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible((prevVisible) => ({
            ...prevVisible,
            [entry.target.dataset.section]: true,
          }));
        } else {
          setIsVisible((prevVisible) => ({
            ...prevVisible,
            [entry.target.dataset.section]: false,
          }));
        }
      });
    });

    const refs = sectionRefs.current;

    Object.values(refs).forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      Object.values(refs).forEach((ref) => {
        observer.unobserve(ref);
      });
    };
  }, []);


  return (
    <>
    

        <>
          <Header />
          <Hero />
          <section id="Hero-1">
          <div id="error-page">
          <h1>Oops! An unexpected error has occurred.</h1>
          <p>Please try again later.</p>
        </div>
          </section>
          <div className="main">

          </div>
          <Footer />
        </>
    </>
  );
};

export default Home;
