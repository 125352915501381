import React from "react";

const Footer = () => {
  return (
    <>
    <footer>© Copyright All rights reserved tmat.io </footer>
    </>
  );
};

export default Footer;