
import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


//error handle for deprecated library until update
console.error = (message) => {
  if (message.startsWith('Warning: React does not recognize')) {
    console.log("react-slick currentSlide warning handler")
    return;
  }
  if (message.includes('currentSlide')) {
    return;
  }
  console.error(message);
};

const ImageGallery = () => {
  // Sample image URLs
  const images = [
    {
      id: 1,
      src: "img/office1.jpg",
      alt: "Image 1",
      title: "Multi Industry Business Process Innovation",
      description:
        "Experience innovation across diverse industries, enhance efficiency, and drive growth with us",
    },
    {
      id: 2,
      src: "img/office2.jpg",
      alt: "Image 2 ",
      title: "Market Research",
      description:
        "We analyze data for trends, preferences, and competition, providing growth recommendations",
    },
    {
      id: 3,
      src: "img/office3.jpg",
      alt: "Image 3",
      title: "Design Thinking",
      description: 
        " Crafting Solutions Tailored to Your Needs according to preferences and budget",
    },
    {
      id: 4,
      src: "img/office4.jpg",
      alt: "Image 4",
      title: "Adoption of Advanced Tech/Develop MVP",
      description:
        "Leverage cutting-edge technology. Our experts guide your digital journey, turning your ideas into reality",
    },
    {
      id: 5,
      src: "img/office5.jpg",
      alt: "Image 5",
      title: "Talent on Demand (pay as you go)",
      description:
        "Access skilled professionals as you need them with our pay-as-you-go model",
    },
    {
      id: 6,
      src: "img/office6.jpg",
      alt: "Image 6",
      title: "Content Development & Migration",
      description:
        "We craft compelling content and ensure seamless transitions to modern platforms, making your online presence stand out",
    },
    

  ];

  // Slick carousel settings
  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <div className="arrow next-arrow">&gt;</div>,
    prevArrow: <div className="arrow prev-arrow">&lt;</div>,
    responsive: [
      {
        breakpoint: 1126, // Adjust the breakpoint as per your desired tablet screen size
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 626, // Adjust the breakpoint as per your desired mobile screen size
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Ref for the Slider component
  const sliderRef = useRef(null);

  // Event handler for the "next" arrow
  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  // Event handler for the "previous" arrow
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
<div className="h-overflow">          <div className="container">
      <h1>Our Specialisation</h1>
      <div className="arrows-container">
          <div className="arrow prev-arrow" onClick={handlePrev}>
            <div>&lt;</div>
         
          </div>
          <div className="arrow next-arrow" onClick={handleNext}>
            <div>&gt;</div>
          
          </div>
        </div>
      <div className="image-gallery-container">
        <Slider ref={sliderRef} {...settings}>
          {images.map((image) => (
            <div key={image.id} className="slick-slide">
              <img src={image.src} alt={image.alt} className="slick-image" />
              <div className="description">
                <h2>{image.title}</h2>
                <p>{image.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div></div>



  );

};

export default React.memo(ImageGallery);