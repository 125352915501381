import React from "react";

const About = () => {
  return (
<>
<div className="container">
<h1>Build Studio Today</h1> 
<div className="sub-container">
<div className="cont-1"><p>Run as an independent business unit ring fenced for your business and reduce risk and liability on your core services & operations.</p>
<p>We help safeguard the core brand during pilots and proof of concepts. It's modular and brings in a 'start up' culture with its efficiencies.</p>
<p>On a quarterly basis, you can adjust the scope of the project, track performance through quarterly P&L, SLAs, and KPIs, all managed by our composite governance team. Plus, access senior consultants and executives at affordable rates.</p>
</div>
<div className="cont-2">
  <img src="img/keyboard-img.jpg" alt="" />
</div>
</div>
</div>

</>
  );
};

export default About;