import React from 'react';
import ReactDOM from 'react-dom/client';
import ErrorPage from './ErrorPage';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Landing from './Landing';
import Home from './Home';
import "./App.css"; 
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Import Helmet and HelmetProvider


const router = createBrowserRouter([
  {
    path: "/",
    element:  <Home />,
    errorElement: <ErrorPage />,
  },  
  {
    path: "/landing",
    element:  <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: '*', // Catch-all route
    element: <ErrorPage />, // Render ErrorPage for unmatched URLs
  },
  
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
    <Helmet> {/* Use Helmet component to set meta tags */}

    <title>Phase 0 Studio</title>
        <meta name="description" content="Run as an independent business" />
        <meta name="keywords" content="tmat, tmat.io, phase0studio, phase0" />
        <link rel="icon" type="image/x-icon" href="/favicon.png" />
        
        {/* Metadata for an image */}
        <meta name="image" content="/favicon.png" />
        <meta name="image:title" content="tmat.io" />

      </Helmet>
      <RouterProvider router={router} />
      </HelmetProvider>
   
  </React.StrictMode>
);

