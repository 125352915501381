import React from "react";

const Market = () => {
  return (
    <>
<div className="container">
<h1>Who wants the Emmanon Experience?</h1> 
<h2>Our ideal market</h2>
<div className="sub-container">
<div className="cont-1">
<img src="img/exp2.jpg" alt="" />
  <div className="cont-content">
  <h1>Individual
professionals</h1>
  <p>
  They aim to find a productive workplace that fosters their skills and innovative thinking. In such an environment, they can contribute effectively and bring fresh ideas to the table.</p>
  </div>

  </div>
<div className="cont-2">
<img src="img/exp1.jpg" alt="" />
  <div className="cont-content">
  <h1>Families
and Groups</h1>
  <p>
  Young and busy professionals, they seek both relaxation and a productive work environment. They aim to unwind and refresh after a demanding workweek while also finding spaces that encourage their productivity.</p>
  </div>
</div>
</div>
</div>

</>
  );
};

export default Market;