import React from "react";

const Advantage = () => {
  return (
<>
<div className="container">
<div className="tmato-mini"><h1>How does it work?</h1> <img src="img/tmat.png" alt="" /></div>
<div className="sub-container">
  <p>The Studio will support the existing business, innovation & tech teams to:</p>
<div className="cont-1">
<h2>Identify Use Cases</h2>
<p>through 3 different lenses (Now, Next, New) for the adoption of emerging technologies in parallel to the development of the in-house technology infrastructure (e.g. Zenith)</p>
</div>
<div className="cont-2">
<h2>Quickly Build & Test MVPs</h2>
<p>by using an on-demand scrum team (as a part of the Cube set up) to build stand alone tools, widgets and apps before they are migrated to a more stable core internal IT infrastructure on proving their worth/realising business value in a test, friendly environment</p>


</div>

{/* <div className="cont-3">
<h2>Customizable packages.</h2>
<p>Customers can pick and choose their treatment and tools according to preferences and budget.</p>


</div>

<div className="cont-4">
<h2>The full experience.</h2>
<p>We don't just give customers their treatment, we give them the full spa experience.</p>


</div> */}

</div>
</div>

</>
  );
};

export default Advantage;

