import React, { useState } from "react";

const Header = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleMenuItemClick = () => {
    setIsChecked(false);
  };

  return (
    <header>
        <div className="logo">
    
          <h1>
            tmat.i <img src={"img/logo.png"} alt="Logo" />
          </h1>
        </div>
      
      <nav className="navbar">
        <div className="nav-container">
          <input
            className="checkbox"
            type="checkbox"
            name=""
            id=""
            checked={isChecked}
            onChange={handleCheckboxToggle}
          />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <div className="menu-items">
            <ul>
            <li>
              <a href="/" onClick={handleMenuItemClick}>
                Home
              </a>
            </li>
            <li>
              <a href="#About" onClick={handleMenuItemClick}>
                About
              </a>
            </li>
            <li>
              <a href="#ImageGallery" onClick={handleMenuItemClick}>
                Features
              </a>
            </li>
            <li>
              <a href="#Phase0page" onClick={handleMenuItemClick}>
                Studio
              </a>
            </li>
            <li>
              <a href="#Faq" onClick={handleMenuItemClick}>
                FAQ
              </a>
            </li>
            <li>
              <a href="mailto:km@tmat.io" onClick={handleMenuItemClick}>
                Contact
              </a>
            </li>
          </ul></div>
          
        </div>
      </nav>
    </header>
  );
};

export default Header;
